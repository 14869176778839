<template>
  <div :class="classes">
    <img v-show="src && !error" :id="id" :class="classes" :src="src" :alt="name" @error="handleError" />
    <span v-show="!src || error" :id="id" :class="[classes, getAvatarColor()]">
      <span class="symbol-label">{{ getFirstLetter() }}</span>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: [String],
      default: '',
    },
    name: {
      type: [String],
      default: '',
    },
    classes: {
      type: [String],
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
    onlyImg: {
      type: [Boolean],
      default: false,
    },
    textkey: {
      type: [String],
      default: 'symbol',
    },
    initialLetters: {
      type: [String, Number],
      default: 1,
    },
    divided: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  methods: {
    handleError(e) {
      if (this.src) {
        this.error = true;
        this.$emit('error');
      }
    },

    getFirstLetter() {
      let name = this.name;
      if (this.divided !== null) {
        name = this.name
          .split(this.divided)
          .map((el) => el[0])
          .join('');
      }
      return name.substring(0, this.initialLetters).toUpperCase();
    },
    getAvatarColor() {
      const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'];
      const color = colors[this.getFirstLetter().charCodeAt() % 7];
      return `symbol-light-${color}`;
    },
  },
};
</script>
