<template>
  <notifications v-bind="$attrs" :width="width">
    <template slot="body" slot-scope="props">
      <b-alert class="notification-alert" :show="props.item.length" dismissible :variant="props.item.type" @dismissed="props.close">
        <h5 v-if="props.item.title" v-html="props.item.title" :class="{ 'mb-0': !props.item.text }"></h5>
        <p v-if="props.item.text" v-html="props.item.text" class="mb-0"></p>
      </b-alert>
    </template>
  </notifications>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'CsNotification',
  props: {
    width: {
      type: [String],
      default: '360px',
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-alert {
  box-shadow: 1px 2px 4px 0px rgb(82 63 105 / 30%);
  margin: 10px 15px;
}
.vue-notification-group {
  z-index: 9999999999999;
}
</style>
