import Applications from '../../api/Applications';

const state = () => ({
  expireds: [],
  items: [],
  item: {},
  stats: {},
  pagination: {},
});

const getters = {
  stats(state) {
    return state.stats;
  },
  expireds(state) {
    return state.expireds;
  },
  items(state) {
    return state.items;
  },
  item(state) {
    return { ...state.item.attributes, id: state.item.id };
  },
  pagination(state) {
    return state.pagination;
  },
};

const actions = {
  expired({ commit }, params = {}) {
    params.elasticsearch = true;
    params.per = Math.pow(10, 12);
    params.page = 1;
    return new Promise((resolve, reject) => {
      Applications.expired(params)
        .then((result) => {
          commit('SET_EXPIREDS', result.data);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  index({ commit }, params = {}) {
    let infinite = false;

    if (params.infinite != undefined) {
      infinite = true;
      delete params.infinite;
    }

    if (params.order == undefined) params.order = 'desc';
    if (params.order_by == undefined) params.order_by = 'created_at';

    return new Promise((resolve, reject) => {
      Applications.index(params)
        .then((result) => {
          const pagination = {
            page: params.page || 1,
            per_page: params.per || -1,
            last_page: result.meta.total,
          };
          commit('SET_PAGINATION', pagination);
          infinite ? commit('INSERT_TO_ITEMS', result.data || []) : commit('SET_ITEMS', result.data || []);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  stats({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      Applications.stats(params)
        .then((result) => {
          commit('SET_STATS', result);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  create({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applications.create(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.show(id)
        .then((result) => {
          commit('SET_ITEM', result.data);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.getPdf(id)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFullPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.getFullPdf(id)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  update({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applications.update(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applications.delete(params)
        .then((result) => {
          commit('REMOVE_FROM_ITEMS', result.data);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resubmit({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applications.resubmit(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resubmitAfter45({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applications.resubmitAfter45(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateInterview({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('UPDATE_INTERVIEW', data);
    });
  },
  removeExpiredApplication({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('REMOVE_EXPIRED_APPLICATION', id);
    });
  },
  generatePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.generatePdf(id)
        .then((result) => {
          commit('UPDATE_AFTER_PDF_GENERATION', id);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.checkPdf(id)
        .then((result) => {
          const { ready, latest_generation_at, in_progress } = result;
          const params = { id, ready, in_progress, latest_generation_at };
          commit('CHECK_PDF_GENERATION', params);
          resolve(params);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  generateFullPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.generateFullPdf(id)
        .then((result) => {
          commit('UPDATE_AFTER_FULL_PDF_GENERATION', id);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkFullPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applications.checkFullPdf(id)
        .then((result) => {
          const { ready, latest_generation_at, in_progress } = result;
          const params = { id, ready, in_progress, latest_generation_at };
          commit('CHECK_FULL_PDF_GENERATION', params);
          resolve(params);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  UPDATE_AFTER_PDF_GENERATION(state, id) {
    if (state.item && state.item.id == id) {
      state.item.attributes.pdf_without_docs_in_progress = true;
      state.item.attributes.pdf_without_docs_ready = false;
      state.item.attributes.pdf_without_docs_generated_at = new Date().toISOString();
    }
  },
  UPDATE_AFTER_FULL_PDF_GENERATION(state, id) {
    if (state.item && state.item.id == id) {
      state.item.attributes.pdf_with_docs_in_progress = true;
      state.item.attributes.pdf_with_docs_ready = false;
      state.item.attributes.pdf_with_docs_generated_at = new Date().toISOString();
    }
  },
  CHECK_PDF_GENERATION(state, { id, in_progress, ready, latest_generation_at }) {
    if (state.item && state.item.id == id) {
      state.item.attributes.pdf_without_docs_in_progress = in_progress;
      state.item.attributes.pdf_without_docs_ready = ready;
      state.item.attributes.pdf_without_docs_generated_at = latest_generation_at;
    }
  },
  CHECK_FULL_PDF_GENERATION(state, { id, in_progress, ready, latest_generation_at }) {
    if (state.item && state.item.id == id) {
      state.item.attributes.pdf_with_docs_in_progress = in_progress;
      state.item.attributes.pdf_with_docs_ready = ready;
      state.item.attributes.pdf_with_docs_generated_at = latest_generation_at;
    }
  },
  UPDATE_INTERVIEW(state, payload) {
    const applicationId = payload.attributes.application.data.id;
    state.items.forEach((item) => {
      if (item.id == applicationId) {
        item.attributes.last_interview_schedule = {
          data: payload,
        };
        item.attributes.status = 'Interview Scheduled';
      }
    });

    if (state.item.id == applicationId) {
      state.item.attributes.last_interview_schedule = {
        data: payload,
      };
      state.item.attributes.status = 'Interview Scheduled';
    }
  },
  UPDATE_PAGINATION_PAGE(state, payload) {
    state.pagination.page = payload.page;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  INSERT_TO_ITEMS(state, payload) {
    state.items = state.pagination.page == 1 ? payload : [...state.items, ...payload];
  },
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  SET_EXPIREDS(state, payload) {
    state.expireds = payload;
  },
  SET_STATS(state, payload) {
    state.stats = payload;
  },
  REMOVE_FROM_ITEMS(state, payload) {
    state.items = state.items.filter((item) => {
      return item.id != payload.id;
    });
    if (state.item.id == payload.id) state.item = {};
  },
  REMOVE_EXPIRED_APPLICATION(state, id) {
    state.expireds = state.expireds.filter((item) => {
      return item.id != id;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
