import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/helpers/jwt.service';
import store from '@/store';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL; // process.env.NODE_ENV === 'production' ? "/admin/api/" :
    this.setHeader();
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    this.setHeader();
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      this.handleHttpErrors(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    this.setHeader();
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      this.handleHttpErrors(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config = {}) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params, config).catch((error) => {
      this.handleHttpErrors(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}/${slug}`, params).catch((error) => {
      this.handleHttpErrors(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params).catch((error) => {
      this.handleHttpErrors(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(resource).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`).catch((error) => {
        this.handleHttpErrors(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
    });
  },

  handleHttpErrors(error) {
    //  handle request errors
    if (error && error.response) {
      const status = error.response.status;

      // const unauthorized = status == 401 || (status == 419 && error.response.data.message == 'CSRF token mismatch.');
      // if (unauthorized) ;

      if (status == 422) {
        let errors = error.response.data.errors;

        if (errors == undefined) errors = error.response.data.error;

        store.dispatch('notifications/notfiy', { code: status, errors }).then((result) => {});
      }

      if (status == 400) {
        let text = error.response.data.error;
        if (error.response.data.errors) text = handle422(error.response.data);

        store.dispatch('notifications/notfiy', { code: status, text }).then((result) => {});
      }

      if (status == 500 || status == 404) {
        store
          .dispatch('notifications/notfiy', {
            code: status,
            text: error.response.data.error || error.response.data.result,
          })
          .then((result) => {});
      }
    }
  },
};

export default ApiService;
