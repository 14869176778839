<template>
  <v-app>
    <router-view />
    <cs-notification position="bottom left" group="main"> </cs-notification>
  </v-app>
</template>

<style lang="sass">
@import "~vuetify/src/components/VDataIterator/VDataFooter.sass"
@import "~vuetify/src/components/VDataTable/VDataTable.sass"
@import "~vuetify/src/components/VDataTable/VDataTableHeader.sass"
@import "~vuetify/src/components/VProgressLinear/VProgressLinear.sass"
@import "~vuetify/src/components/VProgressCircular/VProgressCircular.sass"
@import "~vuetify/src/components/VPagination/VPagination.sass"
@import "~vuetify/src/components/VInput/VInput.sass"
@import "~vuetify/src/components/VTextField/VTextField.sass"
@import "~vuetify/src/components/VSelect/VSelect.sass"
@import "~vuetify/src/components/VList/VList.sass"
@import "~vuetify/src/components/VList/VListItem.sass"
@import "~vuetify/src/components/VMenu/VMenu.sass"
@import "~vuetify/src/components/VBtn/VBtn.sass"
</style>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'src/assets/styles/style.vue';
@import 'src/assets/styles/main';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { mapActions } from 'vuex';
import CsNotification from '@/components/alerts/CsNotification';

export default {
  name: 'MetronicVue',
  components: {
    CsNotification,
  },
  mounted() {
    if (!this.isApplicant && this.isAuthenticated) {
      this.getNotifications();
    }
  },
  methods: {
    ...mapActions({
      getNotifications: 'notifications/index',
    }),
  },
};
</script>
