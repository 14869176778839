import request from '@/helpers/api';

class Transactions {
  static index(params) {
    return request({
      url: 'transactions',
      method: 'get',
      params,
    });
  }
}

export default Transactions;
