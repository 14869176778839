import Resources from '../../api/Resources';

const state = () => ({
  cooperatives: [],
});

const getters = {
  cooperatives: (state) => {
    return state.cooperatives
      .filter((c) => c.attributes && c.attributes.status == 'Active')
      .map((c) => {
        return {
          text: c.attributes.company_name,
          value: c.id,
        };
      });
  },
};

const actions = {
  cooperatives({ commit }, params = {}) {
    params.per = Math.pow(10, 12);
    params.page = 1;
    return new Promise((resolve, reject) => {
      Resources.cooperatives(params)
        .then((result) => {
          const data = { data: result.data || [], state: 'cooperatives' };
          commit('SET_STATE', data);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  SET_STATE(state, data) {
    state[data.state] = data.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
