import request from '@/helpers/api';

class Resources {
  static cooperatives(params) {
    return request({
      url: '/cooperatives',
      method: 'get',
      params,
    });
  }
}

export default Resources;
