<template>
  <div class="wizard-custom-content">
    <div class="wizard-custom-content-inner">
      <div class="wizard-custom-content-header">
        <div class="container-fluid py-4 px-7">
          <b-row class="align-items-center justify-content-between">
            <b-col class="d-flex align-items-center">
              <router-link
                  to="/"
                  v-slot="{ href, navigate }"
              >
                <a
                    :href="href"
                    class="wizard-custom-content-header__brand"
                    @click="navigate"
                >
                  <img
                      class="wizard-custom-content-header__brand-logo img-fluid"
                      :src="logo"
                      alt="App Express"
                      width="33"
                  />
                </a>
              </router-link>
              <h4 class="wizard-custom-content-header__title ml-4">
                <strong class="font-weight-bolder">Step {{ stepNumber }}</strong>:
                {{ title }}
              </h4>
            </b-col>
            <b-col cols="auto">
              <slot name="save-btn" />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="wizard-custom-content-subheader">
        <div class="container-fluid p-7">
          <!--begin: Wizard Nav-->
          <slot name="nav" />
          <!--end: Wizard Nav-->
        </div>
      </div>
      <div class="wizard-custom-content-body">
        <b-container>
          <!--begin: Wizard Body-->
          <b-card class="card-custom">
            <div class="w-lg-600px mx-auto">
              <slot name="body" />

              <div class="d-flex align-items-center justify-content-between mt-10">
                <slot name="footer" />
              </div>
            </div>
            <div class="mx-auto">
              <slot name="body-custom" />
            </div>
          </b-card>
          <!--end: Wizard Body-->
        </b-container>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/pages/wizard/wizard-4";
</style>

<script>


export default {
  name: "KTWizardStepContent",
  props: {
    title: String,
    stepNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      logo: "/media/logos/logo.png"
    }
  }
}
</script>
