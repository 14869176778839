<template>
  <div class="wizard-custom-content-nav">
    <div class="wizard-custom-content-nav-list" :class="[wizardContentNnavListClass]">
      <!--begin::Wizard Step Nav-->
      <div v-if="items">
        <template v-for="(item, i) in items">
          <KTWizardStepContentNavItem v-bind:key="i" :order-number="item.orderNumber || i + 1" :title="item.title" :isChecked="item.isChecked" />
        </template>
      </div>
      <slot />
      <!--end::Wizard Step Nav-->
    </div>
    <!--    <div class="wizard-custom-content-progress">-->
    <!--      <b-progress-->
    <!--          :value="progressValue"-->
    <!--          :max="100"-->
    <!--          variant="success"-->
    <!--          size="10"-->
    <!--          class="rounded-0"-->
    <!--          animated-->
    <!--      />-->
    <!--    </div>-->
  </div>
</template>

<script>
import KTWizardStepContentNavItem from '@/components/generic/WizardStepContentNavItem';

export default {
  name: 'KTWizardStepContentNavList',
  components: {
    KTWizardStepContentNavItem,
  },
  props: {
    items: Array,
    wizardContentNnavListClass: String,
    // progressValue: {
    //   type: Number,
    //   default: 0
    // }
  },
};
</script>
