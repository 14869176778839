<template>
  <div class="spinner-loader" :class="{ 'bg-lighter': bgLighter, 'bg-transparent': bgTransparent }">
    <div class="spinner" :class="[spinnerClass]"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    spinnerClass: {
      type: [String],
      default: 'spinner-lg spinner-primary',
    },
    bgLighter: {
      type: Boolean,
      default: false,
    },
    bgTransparent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.spinner-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 4;
}
.bg-lighter {
  &:not(.bg-transparent) {
    background-color: #ffffffc9 !important;
  }
}
</style>
