import JwtService from '@/helpers/jwt.service';
import Auth from '../../api/Auth';

// action types
export const VERIFY_AUTH = 'auth/verifyAuth';
export const LOGIN = 'auth/login';
export const LOGOUT = 'auth/logout';
export const REGISTER = 'auth/register';
export const RESET = 'auth/passwordReset';
export const PASSWORD_FORGOT = 'auth/passwordForgot';

const state = {
  errors: null,
  roles: [],
  user: {},
  isAuthenticated: false,
};

const getters = {
  user(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  roles(state) {
    return state.roles;
  },
  errorMessage(state) {
    return state.errors;
  },
};

const actions = {
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      Auth.login({ admin_user: credentials })
        .then((response) => {
          context
            .dispatch('setAuth', response)
            .then((result) => {
              resolve(response);
            })
            .catch((err) => {
              context.commit('SET_ERROR', [err.message]);
              reject(err);
            });
        })
        .catch((response) => {
          context.commit('SET_ERROR', response.data.errors);
          reject(response);
        });
    });
  },
  logout(context) {
    return new Promise((resolve, reject) => {
      context.commit('LOG_OUT');
      resolve();
    });
  },
  register(context, credentials) {
    return new Promise((resolve, reject) => {
      Auth.signup({ admin_user: credentials })
        .then((response) => {
          context
            .dispatch('setAuth', response)
            .then((result) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((response) => {
          context.commit('SET_ERROR', response.data.errors);
          reject(response);
        });
    });
  },
  profile({ commit }) {
    return new Promise((resolve, reject) => {
      Auth.profile()
        .then((result) => {
          const roles = result.data.attributes.groups.data.map((g) => {
            return g.attributes.name;
          });
          commit('SET_ROLES', roles);
          commit('SET_AUTHENTICATED', true);
          commit('SET_USER', result.data);
          resolve(result);
        })
        .catch((response) => {
          JwtService.destroyToken();
          commit('SET_ROLES', []);
          commit('SET_AUTHENTICATED', false);
          commit('SET_USER', null);
          commit('SET_ERROR', response.data.errors);
          reject(response);
        });
    });
  },

  updateProfile({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      Auth.updateProfile(params)
        .then((result) => {
          commit('UPDATE_PROFILE_DATA', params);

          resolve(result);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  passwordReset({ commit }, data = {}) {
    return new Promise((resolve, reject) => {
      const { token, params } = data;
      Auth.passwordReset(token, params)
        .then((result) => {
          resolve(result);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  passwordForgot({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      Auth.passwordForgot(params)
        .then((result) => {
          resolve(result);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  authInit(context, params) {
    return new Promise((resolve, reject) => {
      const isAuth = JwtService.getToken();
      context.commit('SET_AUTHENTICATED', Boolean(isAuth));
      if (isAuth && !context.getters.user.id) {
        context
          .dispatch('profile')
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve(true);
      }
    });
  },

  setAuth({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const data = payload.admin_user.data.attributes;
      const roles = data.groups.data.map((g) => {
        return g.attributes.name;
      });

      if (roles.includes('Admin') || (data.board_president && !data.board_member_active)) {
        reject(new Error('Invalid Email/Password'));
      } else {
        JwtService.saveToken(payload.auth_token);
        commit('SET_AUTHENTICATED', true);
        commit('SET_ROLES', roles);
        commit('SET_ERROR', null);
        commit('SET_USER', payload.admin_user.data);
        resolve(payload.admin_user.data);
      }
    });
  },
};

const mutations = {
  SET_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload;
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  UPDATE_PROFILE_DATA(state, payload) {
    for (const key in payload) {
      if (state.user[key] != undefined) state.user[key] = payload[key];
    }
  },

  SET_ERROR(state, error) {
    state.errors = error;
  },

  SET_USER(state, user) {
    state.user = !user ? {} : { id: user.id, type: user.type, ...user.attributes };
  },
  LOG_OUT(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
