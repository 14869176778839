<template>
  <div :class="[baseClasses]">
    <slot name="card-header">
      <div v-if="!hideHeader" :class="[headerClasses]">
        <div class="card-toolbar w-100">
          <div class="w-100">
            <b-row>
              <b-col v-if="searchable" v-bind="searchAttr" class="my-2 my-md-0">
                <div class="input-icon" :class="[searchInputConClass]">
                  <b-form-input type="text" placeholder="Search..." v-model="filters.query" @input="search" />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </b-col>
              <b-col v-if="filterable" v-bind="filterableAttr" class="my-2 my-md-0">
                <div class="d-flex align-items-center">
                  <label class="mr-3 mb-0 d-none d-md-block">{{ filterableName }}:</label>
                  <b-form-select v-model="filters[filterableKey]" :options="filterableOptions" @change="handleFilterableSelect" />
                </div>
              </b-col>
              <slot name="after-header-filterable"></slot>
            </b-row>
          </div>
        </div>
        <slot name="after-header-card-toolbar"></slot>
      </div>
    </slot>
    <div :class="[tableContainerClasses]">
      <v-data-table
        class="table--pixeel"
        v-model="selecteds"
        :options.sync="voptions"
        hide-default-footer
        v-bind="tableOptions"
        :loading="loading"
        :headers="headersList"
        :items="items"
        :item-key="itemKey"
        :show-expand="showExpand"
        :expanded.sync="expanded"
      >
        <template v-slot:expanded-item="{ item }">
          <slot name="expand" :item="item" />
        </template>
        <template #top>
          <slot name="top"> </slot>
        </template>
        <template v-slot:[getSlotName(header)]="{ item }" v-for="(header, index) in headers">
          <slot :name="getSlotName(header)" :item="item" :index="index" :getColor="getColorByName">
            {{ get(item, header.value, header.falsy || '') }}
          </slot>
        </template>

        <template #footer>
          <div class="v-data-table-paginations v-data-footer pt-5 justify-content-between">
            <div class="d-flex align-items-center">
              <span class="font-size-13">Rows per page:</span>
              <v-select
                v-model="filters.per"
                :items="itemsPerPageOptions"
                class="per-page-select pt-0 ml-4 mt-0 font-size-13"
                :menu-props="{ 'content-class': 'font-size-13' }"
                @change="handlePerPageSelection"
              ></v-select>
            </div>
            <v-pagination v-if="pagination.last_page > 0" total-visible="7" v-model="filters.page" :length="pagination.last_page" @input="handlePageinated"></v-pagination>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import colorsMixin from '@/mixins/colors-mixin';
import { debounce } from 'lodash';

export default {
  mixins: [colorsMixin],
  props: {
    selecteds: {
      type: [Array],
      default: () => [],
    },
    headers: {
      type: [Array],
      default: () => [],
    },
    items: {
      type: [Array],
      default: () => [],
    },
    options: {
      type: [Object],
      default: () => {},
    },
    tableOptions: {
      type: [Object],
      default: () => {},
    },
    pagination: {
      type: [Object],
      default: () => {},
    },
    perPagesOptions: {
      type: [Array],
      default: () => [10, 20, 30, 50, 100, -1],
    },
    itemKey: {
      type: [String],
      default: 'id',
    },
    defaultColors: {
      type: [Array],
      default: () => [],
    },
    loading: {
      type: [Boolean],
      default: false,
    },
    tableContainerClasses: {
      type: [String],
      default: 'card-body',
    },
    headerClasses: {
      type: [String],
      default: 'card-header',
    },
    baseClasses: {
      type: [String],
      default: 'card card-custom card-stretch gutter-b',
    },
    hideHeader: {
      type: [Boolean],
      default: false,
    },
    filterable: {
      type: [Boolean],
      default: true,
    },
    searchable: {
      type: [Boolean],
      default: true,
    },
    filterableName: {
      type: [String],
      default: 'Status',
    },
    filterableKey: {
      type: [String],
      default: '',
    },
    filterableList: {
      type: [Array],
      default: () => [],
    },
    filterableListAllOption: {
      type: [Boolean],
      default: true,
    },
    filterableAttr: {
      type: [Object],
      default: () => {},
    },
    searchAttr: {
      type: [Object],
      default: () => {},
    },
    searchInputConClass: {
      type: [String],
      default: 'w-lg-450px',
    },
    showExpand: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      voptions: null,
      filters: {
        query: '',
        order_by: '',
        order: '',
        page: 1,
        per: '',
      },
    };
  },

  computed: {
    filterableOptions() {
      const data = this.$_.cloneDeep(this.filterableList);
      if (this.filterableListAllOption) data.unshift({ text: 'View All', value: null });
      return data;
    },
    headersList() {
      const data = this.headers;
      data.forEach((item) => {
        if (item.sortable == undefined || !item.sortable) item.sortable = false;
      });
      return data;
    },
    itemsPerPageOptions() {
      const data = [];
      for (const number of this.perPagesOptions) {
        data.push({
          text: number == -1 ? 'All' : number,
          value: number,
        });
      }
      return data;
    },
  },
  watch: {
    '$route.query.page': {
      handler(newValue, oldValue) {
        this.filters.page = parseInt(newValue);
      },
    },
    voptions: {
      handler(newValue, oldValue) {
        if (newValue && oldValue) {
          const c3 = newValue.sortBy[0] != oldValue.sortBy[0];
          const c4 = newValue.sortDesc[0] != oldValue.sortDesc[0];
          if (c3 || c4) {
            this.filters.order_by = newValue.sortBy[0];
            this.filters.order = newValue.sortDesc.length > 0 ? (newValue.sortDesc[0] ? 'desc' : 'asc') : '';
            this.emitFilter();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initFilters();
  },
  methods: {
    getColorByName(name, light = false, c = []) {
      const colors = c.length > 0 ? c : this.defaultColors;

      return this.getColor(name, light, colors);
    },
    getSlotName(header) {
      return `item.${header.value}`;
    },
    initFilters() {
      const query = this.$route.query;
      this.filters = {
        query: query.query || '',
        [this.filterableKey]: query[this.filterableKey] || null,
        order_by: query.order_by,
        order: query.order,
        page: parseInt(query.page) || 1,
        per: parseInt(query.per) || this.perPagesOptions[0],
      };
      this.voptions = {
        itemsPerPage: -1,
        sortBy: [this.filters.order_by || ''],
        sortDesc: [this.filters.order == 'desc'],
      };
    },

    handlePageinated(page) {
      this.emitFilter();
    },

    handlePerPageSelection(val) {
      this.filters.page = 1;
      this.emitFilter();
    },

    handleFilterableSelect(val) {
      this.filters.page = 1;
      this.emitFilter();
    },

    search: debounce(function(e) {
      this.filters.page = 1;
      this.emitFilter();
    }, 700),

    emitFilter() {
      const data = this.deleteEmptyParams(this.filters);
      this.$emit('filtered', data);
    },
  },
};
</script>

<style lang="scss">
.per-page-select {
  .v-input__slot {
    margin: 0 !important;
  }
  .v-text-field__details {
    display: none;
  }
}
.v-data-table-paginations {
  .v-pagination__more {
    color: #127dea !important;
  }
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: 0px 3px 1px -2px #f5f8fa, 0px 2px 2px 0px #e1f0ff, 0px 1px 5px 0px #e8ebec !important;

    &:not(.v-pagination__item--active) {
      color: #127dea !important;
      i.v-icon {
        color: #127dea !important;
      }
    }
  }
  .v-pagination__item--active {
    pointer-events: none !important;
  }
}
</style>
