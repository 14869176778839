import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import ApiService from '@/helpers/api.service';
import MainMixin from '@/mixins/main-mixin';
import { Money } from 'v-money';

// import MockService from "@/core/mock/mock.service";

Vue.config.productionTip = false;

// await-to-js
import to from 'await-to-js';

// jquery
import jquery from 'jquery';
window.$ = jquery;

Vue.prototype.$await = async function(cb) {
  let err, res;

  [err, res] = await to(cb);

  if (err) console.error(err);

  return [err, res];
};
// Sweetalert2
import swal from 'sweetalert2';
window.swal = swal;

const toast = swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: false,
  timer: 3000,
});

window.toast = toast;
// Lodash
import _ from 'lodash';
Vue.prototype.$_ = _;

// Directives
import './directives';

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from '@/plugins/vue-i18n';
import vuetify from '@/plugins/vuetify';
import '@/plugins/portal-vue';
import '@/plugins/vue-moment';
import '@/plugins/bootstrap-vue';
import '@/plugins/perfect-scrollbar';
import '@/plugins/scrollspy';
import '@/plugins/vue-dropzone/vue-dropzone';
import '@/plugins/v-mask';
import '@/plugins/vue-ctk-date-time-picker';
import '@/plugins/highlight-js';
import '@/plugins/inline-svg';
import '@/plugins/apexcharts';
import '@/plugins/metronic';
import '@/plugins/vuelidate';
import '@/plugins/vue-notification';
import '@/plugins/sweetalert2';
import '@/plugins/jquery.mask.js';
import '@mdi/font/css/materialdesignicons.css';

// Globals
import '@/plugins/globals';

import KTWizardStepsList from '@/components/generic/WizardStepsList';
import KTWizardStepsItem from '@/components/generic/WizardStepsItem';
import KTWizardStepContent from '@/components/generic/WizardStepContent';
import KTWizardStepContentNavList from '@/components/generic/WizardStepContentNavList';
import KTWizardStepContentNavItem from '@/components/generic/WizardStepContentNavItem';

Vue.component('KTWizardStepsList', KTWizardStepsList);
Vue.component('KTWizardStepsItem', KTWizardStepsItem);
Vue.component('KTWizardStepContent', KTWizardStepContent);
Vue.component('KTWizardStepContentNavList', KTWizardStepContentNavList);
Vue.component('KTWizardStepContentNavItem', KTWizardStepContentNavItem);

// Money Input
Vue.component('VMoney', Money);

// API service init
ApiService.init();

Vue.mixin(MainMixin);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
