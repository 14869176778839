export default {
  400: {
    title: 'Bad Request | 400',
    text: '',
    type: 'danger',
  },
  401: {
    title: 'Unauthorized',
    text: '',
    type: 'danger',
  },
  422: {
    title: 'Unprocessable entity | 422',
    text: '',
    type: 'danger',
  },
  500: {
    title: 'Internal server error | 500',
    text: '',
    type: 'danger',
  },
  404: {
    title: 'Not found  | 404',
    text: '',
    type: 'danger',
  },
  edited: {
    title: 'Congratulation!',
    text: 'Successfully edited!',
  },
  created: {
    title: 'Congratulation!',
    text: 'Successfully created',
  },
  deleted: {
    title: 'Congratulation!',
    text: 'Successfully deleted',
  },
  saved: {
    title: 'Congratulation!',
    text: 'Successfully saved',
  },
  removed: {
    title: 'Congratulation!',
    text: 'Successfully removed',
  },
};
