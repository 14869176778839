<template>
  <div class="wizard-steps p-8 p-lg-0">
    <div v-if="items">
      <template v-for="(item, i) in items">
        <KTWizardStepsItem
            v-bind:key="i"
            :is-checked="item.isChecked"
            :order-number="item.orderNumber || i + 1"
            :is-optional="item.isOptional"
            :title="item.title"
            :description="item.description"
            :btn-continue="item.continue"
            :btn-edit="item.edit"
            :btn-skip="item.skip"
        />
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
import KTWizardStepsItem from "@/components/generic/WizardStepsItem";

export default {
  name: "KTWizardStepsList",
  components: {
    KTWizardStepsItem
  },
  props: {
    items: Array
  }
}
</script>