export default {
  name: 'ColorsMixin',
  data() {
    return {
      colors: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'],
      lightColors: ['primary', 'success', 'danger', 'warning', 'info'],
    };
  },
  methods: {
    getFirstLetter(name) {
      return name.substring(0, 1).toUpperCase();
    },
    getColor(name, light = false, defaultColors = []) {
      let colors = this.colors;

      if (light) colors = this.lightColors;

      if (defaultColors.length > 0) colors = defaultColors;

      const color = name ? colors[this.getFirstLetter(name).charCodeAt() % colors.length] : colors[0];

      return color;
    },
  },
};
