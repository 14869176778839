<template>
  <div class="wizard-step wizard-custom-section">
    <div
        class="wizard-custom-control"
        :class="{'disabled': !isCurrent && !isChecked, 'current': isCurrent, 'checked': isChecked}"
    >
      <div class="wizard-wrapper d-block">
        <div class="wizard-custom-control-body">
          <div class="wizard-icon">
            <i class="wizard-check ki ki-check"></i>
            <span class="wizard-number">
              {{ orderNumber }}
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              {{ title }}
            </h3>
            <div class="wizard-desc">
              {{ description }}
            </div>
          </div>
          <div class="wizard-btn">
            <slot name="btn" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KTWizardStepsItem",
  props: {
    orderNumber: Number,
    title: String,
    description: String,
    isChecked: {
      type: Boolean,
      default: false
    },
    isCurrent: {
      type: Boolean,
      default: false
    }
  }
}
</script>