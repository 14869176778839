import store from '@/store';

export default function() {
  const authenticated = store.getters['auth/isAuthenticated'];
  const user = store.getters['auth/user'];
  const roles = store.getters['auth/roles'];
  const valid = !!(authenticated && user && (roles.includes('Board Member') || roles.includes('Coordinator')));

  let redirect = undefined;

  if (authenticated && user.id && roles.includes('Applicant')) redirect = { name: 'applications-index' };

  return { invalid: !valid, redirect };
}
