import axios from 'axios';
import { getToken, destroyToken } from '@/helpers/jwt.service';
import store from '@/store';
import { handle422 } from './http.handle';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 35000,
});
service.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) config.headers['Authorization'] = 'Token ' + token;
    return config;
  },
  (error) => {
    console.error('API_ERR:' + error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (!response.status.toString().startsWith(2)) {
      console.log('service.interceptors.response.use response', response);
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  (error) => {
    //  handle request errors
    if (error && error.response) {
      const status = error.response.status;

      // const unauthorized = status == 401 || (status == 419 && error.response.data.message == 'CSRF token mismatch.');
      // if (unauthorized) ;

      if (status == 422) {
        let errors = error.response.data.errors;

        if (errors == undefined) errors = error.response.data.error;

        store.dispatch('notifications/notfiy', { code: status, errors }).then((result) => {});
      }

      if (status == 400) {
        let text = error.response.data.error;
        if (error.response.data.errors) text = handle422(error.response.data);

        store.dispatch('notifications/notfiy', { code: status, text }).then((result) => {});
      }

      if (status == 500 || status == 404) {
        store
          .dispatch('notifications/notfiy', {
            code: status,
            text: error.response.data.error || error.response.data.result,
          })
          .then((result) => {});
      }
    }

    return Promise.reject(error.response);
  }
);

export default service;
