import request from '@/helpers/api';
class Contacts {
  static index(params) {
    return request({
      url: 'contacts',
      method: 'get',
      params,
    });
  }
  static create(params) {
    return request({
      url: 'contacts',
      method: 'post',
      data: params,
    });
  }
  static update(params) {
    const id = params instanceof FormData ? params.get('id') : params.id;
    return request({
      url: 'contacts/' + id,
      method: 'put',
      data: params,
    });
  }

  static show(id) {
    return request({
      url: 'contacts/' + id,
      method: 'get',
    });
  }

  static delete(params) {
    return request({
      url: 'contacts/' + params.id,
      method: 'delete',
      data: params,
    });
  }
}

export default Contacts;
