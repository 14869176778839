import Vue from 'vue';

import CsAvatar from '@/components/form/CsAvatar';
import CsTable from '@/components/form/CsTable';
import CsDropzone from '@/components/form/CsDropzone';
import DatePicker from '@/components/form/DatePicker';
import Spinner from '@/components/generic/Spinner';

Vue.component('CsAvatar', CsAvatar);
Vue.component('CsTable', CsTable);
Vue.component('CsDropzone', CsDropzone);
Vue.component('Spinner', Spinner);
Vue.component('DatePicker', DatePicker);
