export default [
  // {
  //   path: '/sitemap',
  //   name: 'sitemap',
  //   component: () => import('@/views/Sitemap'),
  //   meta: {
  //     // middleware: ['authenticated'],
  //   },
  // },
  {
    path: '/',
    component: () => import('@/components/Layout'),
    meta: {
      middleware: ['authenticated'],
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        meta: {
          middleware: ['coordinator_or_boardmember'],
          title: 'Dashboard',
          subtitle: 'Latest updates and statistic charts',
        },
        component: () => import('@/views/Home'),
      },
      {
        path: '/transactions',
        name: 'transactions',
        meta: {
          middleware: ['coordinator_or_boardmember'],
          title: 'Transactions List',
        },
        component: () => import('@/views/Transactions'),
      },
      {
        path: '/contacts',
        name: 'contacts',
        meta: {
          middleware: ['coordinator_or_boardmember'],
          title: 'Contacts List',
        },
        component: () => import('@/views/Contacts'),
      },
      {
        path: '/applications',
        name: 'applications',
        meta: {
          middleware: ['coordinator_or_boardmember'],
          title: 'Applications',
        },
        component: () => import('@/views/Applications'),
      },
      {
        path: '/applications/:id',
        name: 'application-details',
        meta: {
          middleware: ['coordinator_or_boardmember'],
          title: 'Application Details',
        },
        component: () => import('@/views/ApplicationDetails'),
      },
      {
        path: '/notifications',
        name: 'notifications',
        meta: {
          middleware: ['coordinator_or_boardmember'],
          title: 'Notifications',
        },
        component: () => import('@/views/Notifications'),
      },
      {
        path: '/cooperatives',
        name: 'cooperatives-index',
        meta: {
          title: 'Cooperatives List',
          subtitle: 'Buildings',
          middleware: ['coordinator'],
        },
        component: () => import('@/views/cooperatives/Cooperatives'),
      },
      {
        path: '/cooperatives/view/:cooperativeId',
        component: () => import('@/views/cooperatives-view/CooperativesSingleLayout'),
        meta: {
          middleware: ['coordinator'],
        },
        children: [
          {
            path: '',
            name: 'cooperatives-view-details',
            meta: {
              title: '',
              backUrl: '/cooperatives',
            },
            component: () => import('@/views/cooperatives-view/CooperativesSingleDetails'),
          },
          {
            path: 'board-members',
            name: 'cooperatives-view-board-members',
            meta: {
              title: '',
              backUrl: '/cooperatives',
            },
            component: () => import('@/views/cooperatives-view/CooperativesSingleBoardMembers'),
          },
          {
            path: 'applicants',
            name: 'cooperatives-view-applicants',
            meta: {
              title: '',
              backUrl: '/cooperatives',
            },
            component: () => import('@/views/cooperatives-view/CooperativesSingleApplicants'),
          },
          {
            path: 'instructions',
            name: 'cooperatives-view-instructions',
            meta: {
              title: '',
              backUrl: '/cooperatives',
            },
            component: () => import('@/views/cooperatives-view/CooperativesSingleInstructions'),
          },
        ],
      },
      {
        path: '/cooperatives/cooperatives-create/:cooperativeId?',
        name: 'cooperatives-create',
        meta: {
          title: 'Add/Edit Cooperative',
          backUrl: '/cooperatives',
          middleware: ['coordinator'],
        },

        component: () => import('@/views/cooperatives/create/CooperativesCreate'),
      },

      // Extras
      // {
      //   path: '/components',
      //   name: 'components',
      //   meta: {
      //     title: 'Components',
      //   },
      //   component: () => import('@/views/Components'),
      // },
    ],
  },
  // {
  // 	path: "/cooperatives-single",
  // 	component: () => import("@/views/cooperatives-single/CooperativesSingleLayout"),
  // 	children: [
  // 		{
  // 			path: "/cooperatives/cooperatives-single/details",
  // 			name: "cooperatives-single-details",
  // 			meta: {
  // 				title: "",
  // 				backUrl: "/cooperatives"
  // 			},
  // 			component: () => import("@/views/cooperatives-single/CooperativesSingleDetails")
  // 		},
  // 		{
  // 			path: "/cooperatives/cooperatives-single/board-members",
  // 			name: "cooperatives-single-board-members",
  // 			meta: {
  // 				title: "Dubineer Inc",
  // 				backUrl: "/cooperatives"
  // 			},
  // 			component: () => import("@/views/cooperatives-single/CooperativesSingleBoardMembers")
  // 		},
  // 		{
  // 			path: "/cooperatives/cooperatives-single/applicants",
  // 			name: "cooperatives-single-applicants",
  // 			meta: {
  // 				title: "Dubineer Inc",
  // 				backUrl: "/cooperatives"
  // 			},
  // 			component: () => import("@/views/cooperatives-single/CooperativesSingleApplicants")
  // 		},
  // 		{
  // 			path: "/cooperatives/cooperatives-single/instructions",
  // 			name: "cooperatives-single-instructions",
  // 			meta: {
  // 				title: "Dubineer Inc",
  // 				backUrl: "/cooperatives"
  // 			},
  // 			component: () => import("@/views/cooperatives-single/CooperativesSingleInstructions")
  // 		}
  // 	]
  // },
  {
    path: '/applicant/applications',
    component: () => import('@/views/applications/ApplicationsLayout'),
    meta: {
      middleware: ['authenticated', 'applicant'],
    },
    children: [
      {
        path: '/applicant/applications/applications',
        name: 'applications-index',
        meta: {
          title: 'Application',
        },
        component: () => import('@/views/applications/Applications'),
      },
      {
        path: '/applicant/applications/applications-create',
        name: 'applications-create',
        meta: {
          title: 'Application',
          backUrl: '/applications/applications',
        },
        component: () => import('@/views/applications/ApplicationsCreate'),
      },
      // temp
      // {
      //   path: '/applicant/applications/applications-payment',
      //   name: 'applications-payment',
      //   meta: {
      //     title: 'Payment',
      //   },
      //   component: () => import('@/views/applications/ApplicationsPayment'),
      // },
      // {
      //   path: '/applicant/applications/applications-payment-complete',
      //   name: 'applications-payment-complete',
      //   meta: {
      //     title: 'Payment Complete',
      //   },
      //   component: () => import('@/views/applications/ApplicationsPaymentComplete'),
      // },
      // temp
    ],
  },
  {
    path: '/applicant/applications-single',
    meta: {
      middleware: ['authenticated', 'applicant'],
    },
    component: () => import('@/views/applications-single/ApplicationsSingleLayout'),
    children: [
      {
        path: '/applications/applications-single/details',
        name: 'applications-single-details',
        meta: {
          backUrl: '/applications/applications',
        },
        component: () => import('@/views/applications-single/ApplicationsSingleDetails'),
      },
    ],
  },
  {
    path: '/profile',
    component: () => import('@/views/profile/ProfileLayout'),
    meta: {
      middleware: ['authenticated'],
    },
    children: [
      {
        path: '/profile/personal-information',
        name: 'profile-personal-information',
        meta: {
          // title: '',
          // subtitle: 'Coordinator',
          middleware: ['coordinator'],
        },
        component: () => import('@/views/profile/ProfilePersonalInformation'),
      },
      {
        path: '/profile/banking-details',
        name: 'profile-banking-details',
        meta: {
          // title: '',
          // subtitle: 'Coordinator',
          middleware: ['coordinator'],
        },
        component: () => import('@/views/profile/ProfileBankingDetails'),
      },
      {
        path: '/profile/transactions',
        name: 'profile-transactions',
        meta: {
          // title: '',
          // subtitle: 'Coordinator',
          // middleware: ['coordinator_or_boardmember'],
        },
        component: () => import('@/views/profile/ProfileTransactions'),
      },
      {
        path: '/profile/settings',
        name: 'profile-settings',
        meta: {
          // title: '',
          // subtitle: 'Coordinator',
        },
        component: () => import('@/views/profile/ProfileSettings'),
      },
    ],
  },

  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/Error'),
    meta: {
      title: 'Page not Found',
    },
  },
  {
    path: '/',
    component: () => import('@/components/account/Auth'),
    children: [
      {
        name: 'login',
        path: '/login',
        meta: {
          title: 'Log In',
          middleware: ['guest'],
          guest: true,
        },
        component: () => import('@/components/account/Login'),
      },
      {
        name: 'forgotPassword',
        path: '/forgot-password',
        meta: {
          title: 'Forgot Password',
          middleware: ['guest'],
          guest: true,
        },
        component: () => import('@/components/account/ForgotPassword'),
      },
      {
        name: 'resetPassword',
        path: '/reset-password',
        meta: {
          title: 'Reset Password',
          middleware: ['guest'],
        },
        component: () => import('@/components/account/ResetPassword'),
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/components/Layout'),
    children: [
      {
        name: '',
        path: '',
        meta: {
          title: 'OOPS! Something went wrong here',
          fluid: true,
          // containerClasses: 'p-0',
        },
        component: () => import('@/views/Error'),
      },
    ],
  },
];
