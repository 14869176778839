import { mapGetters } from 'vuex';
export default {
  name: 'MainMixin',
  data() {
    return {
      isPending: false,
      pendingClass: 'spinner spinner-right spinner-light pright-25',
      stateOptions: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
      ],
      defaultMask: {
        zip: '#####',
        routingNumber: '#########',
        bankAccountNumber: '############',
        phone: '(###)###-####',
        social_security_number: '###-##-####',
        sallary: '###########',
        year: '####',
        date: '##/##/####',
      },
      moneyMask: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      perctMask: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '%',
        precision: 0,
        masked: false,
      },
      transactionStatuses: {
        Created: 'info',
        Canceled: 'danger',
        Succeeded: 'success',
        'Payment Failed': 'danger',
        Attached: 'warning',
      },
      flipTaxes: ['Percent per share', 'Dollar per share', 'Flat fee'],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      roles: 'auth/roles',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    isCoordinator() {
      return this.roles.includes('Coordinator');
    },
    isBoardmember() {
      return this.roles.includes('Board Member');
    },
    isBoardmemberPresident() {
      return this.roles.includes('Board Member') && this.user.board_president;
    },
    isApplicant() {
      return this.roles.includes('Applicant');
    },
  },
  methods: {
    centToDollar(val) {
      return val / 100;
    },
    dollarToCent(val) {
      return val * 100;
    },
    moneyDollarForamter(val) {
      return this.moneyFormatter(this.centToDollar(val));
    },
    moneyFormatter(val) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(val);
    },
    percentageFormatter(e) {
      return parseFloat(e) > 100 ? 100 : e;
    },

    generateUid() {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9) +
        new Date().getTime()
      );
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
      }
      return formData;
    },
    jsonToFormData(data) {
      const result = this.buildFormData(new FormData(), data);
      return result;
    },
    getBy(arr, trackBy, value, falsyValue = '') {
      if (!Array.isArray(arr)) return falsyValue;
      return arr.find((el) => el[trackBy] == value) || falsyValue;
    },
    get(obj, slug, falsyValue = '', prefix = '', suffix = '', func = '') {
      if ((!obj && typeof obj != 'object') || obj === null) return falsyValue;
      let result = this.$_.cloneDeep(obj);

      const nests = slug.split('.');

      for (const nest of nests) {
        if (result[nest] === null || typeof result[nest] === 'undefined') {
          result = falsyValue;
          break;
        } else {
          result = result[nest];
        }
      }

      if (result !== falsyValue) {
        if (prefix) result = prefix + result;

        if (suffix) result = result + suffix;

        if (func) result = func(result);
      }

      return result;
    },
    set(obj, path, val) {
      const keys = path.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
      lastObj[lastKey] = val;
    },
    deepEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length != keys2.length) {
        return false;
      }

      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 != val2)) {
          return false;
        }
      }
      return true;
    },

    isObject(object) {
      return object != null && typeof object === 'object';
    },
    isSameStrict(fristObj, secondObj) {
      return this.$_.isEqual(fristObj, secondObj);
    },
    isSame(fristObj, secondObj) {
      return this.deepEqual(fristObj, secondObj);
    },
    deleteEmptyParams(form) {
      let data = this.$_.cloneDeep(form);
      for (const key in data) {
        if (data[key] && typeof data[key] == 'object') {
          data[key] = this.deleteEmptyParams(data[key]);
        } else if (!data[key] && data[key] !== 0 && data[key] !== false) {
          delete data[key];
        }
      }

      return data;
    },
    getStateVal(val) {
      return this.stateOptions.find((s) => s == val) || '';
    },
    getFlipTaxVal(selectFlipTax, flipTax, isDollar = false) {
      if (this.isSelectFlipTaxPercen(selectFlipTax)) return flipTax;
      return isDollar ? this.centToDollar(flipTax) : this.dollarToCent(flipTax);
    },
    isSelectFlipTaxPercen(v) {
      const val = this.getSelectFlipTaxVal(v);
      return val == 'Percent per share' || !val;
    },
    getSelectFlipTaxVal(val) {
      return this.flipTaxes.find((s) => s == val) || '';
    },
    // Validations
    alertValidationFields($vmodel, labels) {
      const keys = $vmodel.$params;
      const fields = [];
      for (const key in keys) {
        if ($vmodel[key].$invalid) {
          fields.push(`<strong>${labels[key]}</strong>`);
        }
      }

      if (fields.length == 0) return;

      Swal.fire({
        html: `${fields[0] !== '<strong>undefined</strong>' ? fields.join(', ') : 'The'} ${
          fields.length == 1 ? 'field is' : 'fields are'
        } invalid`,
        icon: 'error',
        confirmButtonText: 'Ok, got it!',
      });
    },
    validateState($vmodel) {
      const { $dirty, $error } = $vmodel;
      return $dirty ? !$error : null;
    },
    onlyLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z а-яА-Я]+$/.test(char)) return true;
      else e.preventDefault();
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateZip(zip) {
      const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(zip);
    },
  },
};
