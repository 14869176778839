<template>
  <div class="wizard-custom-content-nav-list__item">
    <div class="wizard-custom-content-nav-list__link h-100 d-inline-flex align-items-center WNSCVI" :class="{ disabled: !isCurrent && !isChecked, current: isCurrent, checked: isChecked }">
      {{ title }}
      <span class="wizard-custom-content-nav-list__link-helper">
        <inline-svg :src="dotImg" width="30" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KTWizardStepContentNavItem',
  props: {
    orderNumber: Number,
    title: String,
    isChecked: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dotImg: 'media/svg/icons/Other/dots-grey.svg',
    };
  },
};
</script>
<style scoped>
  .WNSCVI{
    cursor: inherit !important;
  }
  .WNSCVI.current:hover{
    background-color: #3699FF !important;
    border-color: #3699FF !important;
  }
</style>