import { handle422 } from '@/helpers/http.handle';
import list from '@/utils/notifications.js';
import Notifications from '../../api/Notifications';

const state = () => ({
  items: [],
  item: {},
  pagination: {},
});

const getters = {
  items(state) {
    return state.items;
  },
  item(state) {
    return state.item;
  },
  pagination(state) {
    return state.pagination;
  },
};

const actions = {
  index({ commit }, params = {}) {
    let infinite = false;

    if (params.infinite != undefined) {
      infinite = true;
      delete params.infinite;
    }

    return new Promise((resolve, reject) => {
      Notifications.index(params)
        .then((result) => {
          const pagination = {
            page: params.page || 1,
            per_page: params.per || -1,
            last_page: result.meta.total,
          };
          commit('SET_PAGINATION', pagination);
          infinite ? commit('INSERT_TO_ITEMS', result.data || []) : commit('SET_ITEMS', result.data || []);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  notfiy({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.code == 422) payload.text = handle422(payload);
      this._vm.$notify({
        group: payload.group || 'main',
        title: payload.title || (list[payload.code] ? list[payload.code].title || '' : ''),
        text: payload.text || (list[payload.code] ? list[payload.code].text || '' : ''),
        type: payload.type || (list[payload.code] ? list[payload.code].type || '' : ''),
        ignoreDuplicates: typeof payload.ignoreDuplicates != 'undefined' ? payload.ignoreDuplicates : false,
        duration: payload.duration || 15000,
        closeOnClick: false,
      });
      resolve(true);
    });
  },
};

const mutations = {
  UPDATE_PAGINATION_PAGE(state, payload) {
    state.pagination.page = payload.page;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  INSERT_TO_ITEMS(state, payload) {
    const data = payload.map((p) => {
      return { ...p.attributes, id: p.id };
    });

    state.items = state.pagination.page == 1 ? data : [...state.items, ...data];
  },

  SET_ITEMS(state, payload) {
    const data = payload.map((p) => {
      return { ...p.attributes, id: p.id };
    });

    state.items = data;
  },
  SET_ITEM(state, payload) {
    state.item = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
