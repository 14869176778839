export const handle422 = (payload) => {
  const errors = payload.errors;
  let text = '';
  let index = 0;
  const length = Object.keys(errors).length;
  for (const key in errors) {
    index++;
    text += `${key.toUpperCase()} ${errors[key][0]} ${index < length ? '<hr class="my-2">' : ''}`;
  }
  return text;
};
