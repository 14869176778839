import request from '@/helpers/api';
class Auth {
  static profile() {
    return request({
      url: '/admin_users/info',
      method: 'get',
    });
  }

  static updateProfile(params) {
    return request({
      url: '/admin_users/update_info',
      method: 'put',
      data: params,
    });
  }

  static login(params) {
    return request({
      url: '/sign_in',
      method: 'post',
      data: params,
    });
  }

  static signup(params) {
    return request({
      url: '/sign_up',
      method: 'post',
      data: params,
    });
  }

  static logout(params) {
    return request({
      url: '/log_out',
      method: 'post',
      data: params,
    });
  }

  static passwordForgot(params) {
    return request({
      url: '/password_forgot',
      method: 'post',
      data: params,
    });
  }

  static passwordReset(token, params) {
    return request({
      url: '/password_reset',
      method: 'post',
      data: params,
      headers: { token },
    });
  }
}

export default Auth;
