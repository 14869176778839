<template>
  <div>
    <i class="far fa-calendar text-dark flat-pickr-input-icon" @click="focusToPicker"></i>
    <flat-pickr
      v-model="model"
      :config="config"
      :class="uuid"
      class="flat-pickr-input form-control-solid h-auto font-size-h6 border-0 rounded-lg p-5"
      placeholder="__/__/____"
    >
    </flat-pickr>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    flatPickr,
  },
  props: {
    value: {
      type: [String, Date],
      default: '',
    },
    min: {
      type: [String, Date],
      default: '',
    },
    max: {
      type: [String, Date],
      default: '',
    },
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue != this.model) this.model = newValue;
      },
      immediate: true,
    },
    min: {
      handler(newValue, oldValue) {
        this.config.minDate = newValue;
      },
    },
    max: {
      handler(newValue, oldValue) {
        this.config.maxDate = newValue;
      },
    },
  },
  data() {
    return {
      model: '',
      uuid: this.generateUid(),
      config: {
        static: true,
        wrap: true,
        altFormat: 'm/d/Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
        onChange: (selectedDates, dateStr, instance) => {
          this.$emit('input', dateStr);
        },
        minDate: '',
        maxDate: '',
        disableMobile: true,
      },
    };
  },
  mounted() {
    if (this.min) this.config.minDate = this.min;
    if (this.max) this.config.maxDate = this.max;

    $(`.${this.uuid}`).mask('00/00/0000');
  },
  methods: {
    focusToPicker() {
      $(`.${this.uuid}`).focus();
    },
  },
};
</script>

<style lang="scss">
.flatpickr-wrapper {
  width: 100% !important;
}
.flat-pickr-input {
  padding-left: 40px !important;
}
.flat-pickr-input-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}
</style>
