import Applicants from '../../api/Applicants';
const state = () => ({
  items: [],
  item: {},
  pagination: {},
});

const getters = {
  items(state) {
    return state.items;
  },
  item(state) {
    return state.item;
  },
  pagination(state) {
    return state.pagination;
  },
};

const actions = {
  index({ commit }, params = {}) {
    let infinite = false;

    if (params.infinite != undefined) {
      infinite = true;
      delete params.infinite;
    }
    if (params.order == undefined) params.order = 'desc';
    if (params.order_by == undefined) params.order_by = 'id';

    return new Promise((resolve, reject) => {
      Applicants.index(params)
        .then((result) => {
          const pagination = {
            page: params.page || 1,
            per_page: params.per || -1,
            last_page: result.meta.total,
          };
          commit('SET_PAGINATION', pagination);
          infinite ? commit('INSERT_TO_ITEMS', result.data || []) : commit('SET_ITEMS', result.data || []);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  create({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applicants.create(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      Applicants.show(id)
        .then((result) => {
          commit('SET_ITEM', result.data);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  update({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applicants.update(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applicants.delete(params)
        .then((result) => {
          commit('REMOVE_FROM_ITEMS', result.data);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  invite({ commit }, params) {
    return new Promise((resolve, reject) => {
      Applicants.invite(params)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  UPDATE_PAGINATION_PAGE(state, payload) {
    state.pagination.page = payload.page;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  INSERT_TO_ITEMS(state, payload) {
    state.items = state.pagination.page == 1 ? payload : [...state.items, ...payload];
  },
  SET_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  REMOVE_FROM_ITEMS(state, payload) {
    state.items = state.items.filter((item) => {
      return item.id != payload.id;
    });
    if (state.item.id == payload.id) state.item = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
