import authenticated from './authenticated';
import guest from './guest';
import coordinator from './coordinator';
import applicant from './applicant';
import boardmember from './boardmember';
import coordinator_or_boardmember from './coordinator_or_boardmember';
export default {
  authenticated,
  guest,
  coordinator,
  applicant,
  boardmember,
  coordinator_or_boardmember,
};
