<template>
  <vue-dropzone
    :id="id || generateUid()"
    v-bind="$attrs"
    v-on="$listeners"
    :useCustomSlot="true"
    :options="options"
    @vdropzone-success="uploaded"
    @vdropzone-removed-file="removed"
    class="dropzone-preview-w-150 dropzone-default dropzone-single-file"
  >
    <template v-if="typeof value == 'string' && value">
      <div class="cs-dropzone-preview mb-2">
        <a class="cs-dropzone-preview-remove" href="javascript:;" @click.stop.prevent="confirmAndRemove()">Remove file</a>
        <img :src="value" alt="" />
      </div>
    </template>
    <h3 class="dropzone-msg-title">{{ msgTitle }}</h3>
    <div class="dropzone-msg-desc">{{ msgDesc }}</div>
  </vue-dropzone>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [Object, String, Number, File],
      default: '',
    },
    msgTitle: {
      type: [String],
      default: 'Image',
    },
    msgDesc: {
      type: [String],
      default: 'Only images are allowed for upload',
    },

    options: {
      type: [Object],
      default: () => {
        return {
          addRemoveLinks: true,
          thumbnailWidth: 150,
          maxFiles: 1,
          maxFilesize: 10,
          uploadMultiple: false,
          remoOnFileSizeError: true,
          acceptedFiles: 'image/*',
        };
      },
    },
  },
  data() {
    return {
      initial: '',
    };
  },
  mounted() {
    if (this.value) this.initial = this.value;
  },
  methods: {
    updateInitial(val) {
      this.initial = val;
    },
    uploaded(file, data) {
      this.$emit('input', file);
      this.$emit('uploaded', file);
    },
    confirmAndRemove() {
      if (confirm('Are you sure you want to delete this file?')) {
        this.removed();
      }
    },
    removed(file, data) {
      if (!file) {
        this.$emit('removed', file);
      } else {
        this.$emit('input', this.initial || '');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cs-dropzone-preview {
  position: relative;
  img {
    border-radius: 0.42rem;
    width: 150px;
    height: 200px;
    object-fit: cover;
  }
  &-remove {
    position: absolute;
    top: 20px !important;
    bottom: inherit !important;
    left: calc(50% - 52px) !important;
    width: 104px !important;
    margin: 0 !important;
    z-index: 30;
    color: #fff !important;
    padding: 10px;
    top: inherit;
    border: 2px #fff solid;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 800;
    letter-spacing: 1.1px;
    opacity: 0;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    &:hover {
      color: #3699ff !important;
    }
  }
  &:hover {
    .cs-dropzone-preview-remove {
      opacity: 1 !important;
    }
  }
}
</style>
