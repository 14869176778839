import request from '@/helpers/api';
class BoardMembers {
  static index(params) {
    return request({
      url: 'board_members',
      method: 'get',
      params,
    });
  }
  static create(params) {
    return request({
      url: 'board_members',
      method: 'post',
      data: params,
    });
  }
  static update(params) {
    const id = params instanceof FormData ? params.get('admin_user[id]') : params.admin_user.id;
    return request({
      url: 'board_members/' + id,
      method: 'put',
      data: params,
    });
  }

  static show(id) {
    return request({
      url: 'board_members/' + id,
      method: 'get',
    });
  }

  static delete(id) {
    return request({
      url: 'board_members/' + id,
      method: 'delete',
    });
  }
}

export default BoardMembers;
