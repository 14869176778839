import Vue from 'vue';
import _ from 'lodash';
import {VMoney} from 'v-money'

Vue.directive('money', VMoney);

Vue.directive('cs-loading', {
  bind: (el, binding, vnode) => {
    updateLoder(el, binding.value, binding.modifiers);
  },
  update: function(el, binding, vnode) {
    updateLoder(el, binding.value, binding.modifiers);
  },
});

function updateLoder(el, val, modifiers) {
  // let variant = Object.keys(modifiers);
  // variant = variant.length > 0 ? variant[0] : 'light';

  const loading = typeof val == 'object' ? val.loading : val;
  const variant = typeof val == 'object' ? val.variant : 'light';

  const action = loading ? 'add' : 'remove';

  el.classList[action]('spinner', 'spinner-' + variant, 'spinner-right');
}

