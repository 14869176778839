import request from '@/helpers/api';
import { getToken } from '@/helpers/jwt.service';
class Applications {
  static expired(params) {
    return request({
      url: 'applications/expired',
      method: 'get',
      params,
    });
  }
  static index(params) {
    return request({
      url: 'applications',
      method: 'get',
      params,
    });
  }
  static stats(params) {
    return request({
      url: 'applications/statistics',
      method: 'get',
      params,
    });
  }
  static create(params) {
    return request({
      url: 'applications',
      method: 'post',
      data: params,
    });
  }
  static update(params) {
    const id = params instanceof FormData ? params.get('application[id]') : params.application.id;

    return request({
      url: 'applications/' + id,
      method: 'put',
      data: params,
    });
  }

  static show(id) {
    return request({
      url: 'applications/' + id,
      method: 'get',
    });
  }

  static delete(params) {
    return request({
      url: 'applications/' + params.id,
      method: 'delete',
      data: params,
    });
  }
  static resubmit(params) {
    return request({
      url: 'applications/' + params.id + '/resubmit',
      method: 'post',
    });
  }
  static resubmitAfter45(params) {
    return request({
      url: 'applications/' + params.id + '/resubmit_after_autorejection',
      method: 'post',
    });
  }

  static generatePdf(id) {
    return request({
      url: 'applications/' + id + '/generate_pdf_without_docs',
      method: 'get',
    });
  }

  static checkPdf(id) {
    return request({
      url: 'applications/' + id + '/pdf_without_docs_status',
      method: 'get',
    });
  }

  static generateFullPdf(id) {
    return request({
      url: 'applications/' + id + '/generate_pdf_with_docs',
      method: 'get',
    });
  }

  static checkFullPdf(id) {
    return request({
      url: 'applications/' + id + '/pdf_with_docs_status',
      method: 'get',
    });
  }

  static getPdf(id) {
    return request({
      url: 'applications/' + id + '/download_pdf_without_docs?Authorization=Token ' + getToken(),
      method: 'get',
    });
  }
  static getFullPdf(id) {
    return request({
      url: 'applications/' + id + '/download_pdf_with_docs?Authorization=Token ' + getToken(),
      method: 'get',
    });
  }
}

export default Applications;
